<template>
    <div v-if="product" class="action-wrapper">
        <VariantAttrs
            v-if="showVariantAttrs"
            class="mt-3"
            :attr-values="product.variantAttrs.attrValues"
            :valid-options="product.variantAttrs.validOptions"
            :variant-order="product.variantAttrs.variantOrder"
            :readonly="updateMode"
            @on-change="loadNewDetails"
        />
        <ProductFeatures
            v-if="showProductFeatures"
            class="mt-3"
            :product-features="product.productFeatures"
            @update-feature="updateFeature"
            @is-valid="(isValid) => productFeaturesValid = isValid"
        />

        <component
            v-if="product.isPurchasable && !product.bundleParent"
            class="w-100 mt-3"
            :is="'Quantity' + quantityStrategy.type"
            :current-quantity="parseFloat(quantity)"
            :is-quantity-zero-allowed="false"
            :min-quantity-by-pricelist="product.minOrderableQuantity"
            :data="quantityStrategy.data"
            :readonly="!quantityChangeable"
            @quantity-changed="changeQuantity"
        />

        <BudgetSelect
            v-if="availableBudgets.length > 0"
            class="mt-3"
            variant="outline"
            minimal-view
            :costs="budgetRelevantCosts"
            :available-budgets="availableBudgets"
            :selected-budget="selectedBudgetId"
            :invalid="!budgetValid"
            show-additional-shipping-costs-info
            ignore-remaining-value
            @change-budget="setNewBudget"
        />

        <div class="notificationWrapper">
            <NdxNotification
                :duration="5"
                variant="info"
                :message="quantityChangedMsg"
                v-model="quantityChangedNote"
            />
        </div>

        <div class="price-wrapper d-flex align-items-end justify-content-between gap-3">
            <template v-if="product.priceTag">
                <div class="price">
                    {{ product.priceTag }}
                </div>
            </template>
            <template v-else>
                <div class="price-label">
                    {{ priceLabel }}
                    <div v-if="priceInfoText.length > 0">{{ priceInfoText }}</div>
                </div>
                <div class="price flex-shrink-0">
                    {{ formatPrice(priceNumber) }}
                </div>
            </template>
        </div>

        <CallToActions
            v-if="!updateMode"
            :product="product"
            :order-btn-active="orderBtnActive"
            :basket-action-running="basketActionRunning"
            :all-bundle-items-set-zero="allBundleItemsSetZero"
            @add-to-basket-with-cr="addToBasketWithCr"
            @add-to-basket="addToBasket"
            @quick-checkout="quickCheckout"
        />
        <div v-else-if="!bundle" class="mt-4">
            <NdxButton
                @click="updateBasketItem"
                :disabled="!updateBtnActive || basketActionRunning"
            >
                {{ $t('label.updateBasketItem') }}
            </NdxButton>
        </div>
        <NdxButton
            v-if="showBackToBasketBtn"
            class="mt-3"
            :variant="bundle && updateMode ? 'primary' : 'secondary'"
            @click="gotoBasket"
        >
            {{ $t('btn.back') }}
        </NdxButton>
        <div class="last-order" :style="lastOrderStyle" v-if="!product.bundleParent && showOrders && product.lastOrder">
            {{
                $t('label.order_last_order', {
                    date: $d(dateConverter(product.lastOrder.createdAt), 'long'),
                    name: product.lastOrder.createdBy.firstname + ' ' + product.lastOrder.createdBy.lastname
                })
            }}
        </div>

        <ProjectRun
            v-if="!product.bundleParent && campaignRunId"
            :campaign-run-id="campaignRunId"
            :product-name="product.name"
            :campaign-run-approved="false"
            :live-price-calculation="livePriceCalculation"
            @close="onCloseCrEditor"
            @done="gotoBasket"
        />

        <QuickCheckoutFlyInn
            v-if="!product.bundleParent && showQuickCheckout"
            :order-infos="quickCheckoutData.info"
            :btn-label="quickCheckoutLabel"
            store-name="quickCheckout"
            @cancel="cancelQuickCheckout"
            @set-address="setAddressQuickCheckout"
            @order-basket="orderQuickCheckout"
        />

        <NdxFlyIn v-if="quickCheckoutFailed" class="limit500">
            <template #default>
                <NdxIcon icon="error-filled" class="fail me-2"/>
                {{ $t('message.errorsDuringOrdering') }}
            </template>
            <template #buttons>
                <NdxButton class="btnFlex" @click="() => quickCheckoutFailed = false">
                    <div class="d-flex justify-content-between">
                        {{ $t('btn.close') }}
                        <NdxIcon icon="arrow-left" flip-h/>
                    </div>
                </NdxButton>
            </template>
        </NdxFlyIn>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import VariantAttrs from "./VariantAttrs";
    import NdxButton from "../../library/NdxButton";
    import QuantityDiscrete from "../../basket/parts/QuantityDiscrete";
    import QuantityMinMaxStep from "../../basket/parts/QuantityMinMaxStep";
    import ProductFeatures from "./ProductFeatures";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import ProjectRun from "../../projectRun/ProjectRun";
    import { PriceFormatter } from "../../../plugins/formatter";
    import QuickCheckoutFlyInn from "../../basket/QuickCheckoutFlyIn";
    import NdxFlyIn from "../../library/NdxFlyIn";
    import NdxIcon from "../../library/NdxIcon";
    import NdxNotification from "../../library/NdxNotification.vue";
    import CallToActions from "./CallToActions.vue";
    import BudgetSelect from "../../basket/parts/BudgetSelect.vue";

    const BUDGET_NONE_VALUE = -1;

    export default {
        name: 'Actions',
        components: {
            BudgetSelect,
            CallToActions, NdxNotification, NdxIcon, NdxFlyIn, QuickCheckoutFlyInn, ProjectRun, ProductFeatures,
            NdxButton, VariantAttrs, QuantityDiscrete, QuantityMinMaxStep
        },
        props: {
            bundle: Object,
            bundleItems: Array,
        },
        emits: ['refresh-configurator'],
        data() {
            return {
                dateConverter: ndxDateConvert,
                productFeaturesValid: true,
                campaignRunId: null,
                basketActionRunning: false,

                showQuickCheckout: false,
                quickCheckoutData: null,
                quickCheckoutFailed: false,

                quantityChangedNote: false,
                quantityChangedMsg: '',

                selectedBudgetId: BUDGET_NONE_VALUE
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product,
                quantity: state => state.productDetail.quantity,
                quantityChangeable: state => state.productDetail.quantityChangeable,
                price: state => state.productDetail.price,
                additionalPrice: state => state.productDetail.additionalPrice,
                isOrderable: state => state.productDetail.product.isOrderable,
                livePriceCalculation: state => state.shop.basket?.liveCalculation,
                updateMode: state => state.productDetail.updateModus,
                currency: state => state.shop.currency,
                showTax: state => state.shop.showTax,
                deliveryCosts: state => state.shop.deliveryCosts,
                showOrders: state => state.shop.productDetails.showOrders,
                fontColorLastOrder: state => state.shop.productDetails.fontColorLastOrder,
                shopClient: state => state.user.shopClient,
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            }),
            ...mapGetters('basket', [
                'getBasketTextQuickCheckout'
            ]),
            orderBtnActive() {
                return this.productFeaturesValid && this.budgetValid && !this.updateMode && this.isOrderable;
            },

            updateBtnActive() {
                return this.productFeaturesValid && this.updateMode;
            },
            priceLabel() {
                if (this.shopClient.isNet) {
                    return this.$t('label.priceNet');
                }
                return this.$t('label.priceGross');
            },
            priceInfoText() {
                let txt = [];

                if (this.showTax && this.shopClient.isNet) {
                    txt.push(this.$t('label.excl_taxValue', {value: this.product.taxValue}));
                } else if (this.showTax && !this.shopClient.isNet) {
                    txt.push(this.$t('label.incl_taxValue', {value: this.product.taxValue}));
                }

                if (!this.$store.getters['productDetail/hideDelivery']) {
                    if (this.deliveryCosts.show) {
                        txt.push(this.$t('label.plus_delivery'));
                    } else if (!this.deliveryCosts.show && this.deliveryCosts.alternativeText.length > 0) {
                        txt.push(this.deliveryCosts.alternativeText);
                    }
                }

                if (this.additionalPrice > 0) {
                    txt.push(this.$t('label.includeAdditionalCosts', {txt: this.additionalPriceText}));
                }

                return txt.join(', ');
            },
            priceNumber() {
                if (this.product.bundleParent && this.bundle?.bundleProduct) {
                    return this.bundle.bundleProduct.priceNet;
                }
                if (this.product) {
                    let price = this.price;

                    if (!this.shopClient.isNet) {
                        price = price * (1 + (this.product.taxValue / 100));
                    }

                    return price;
                }

                return 0;
            },
            lastOrderStyle() {
                if (this.fontColorLastOrder && this.fontColorLastOrder.length > 0) {
                    return 'color: ' + this.fontColorLastOrder;
                }
                return '';
            },
            quickCheckoutLabel() {
                if (this.product.actionConfig?.length > 0) {
                    const idx = this.product.actionConfig.findIndex(item =>
                        item.active && item.type === 'quickCheckOut' && item.label.length > 0
                    );
                    if (idx > 0) {
                        return this.product.actionConfig[idx].label;
                    }
                }

                return this.getBasketTextQuickCheckout
                    ? this.getBasketTextQuickCheckout
                    : this.$t('btn.quickCheckout');
            },
            additionalPriceText() {
                if (this.product.additionalCostsText && this.product.additionalCostsText.length > 0) {
                    return this.product.additionalCostsText;
                }
                if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    return this.shopAdditionalPriceText;
                }
                return this.$t('label.additionalPrice');
            },
            quantityStrategy() {
                return this.$store.getters['productDetail/orderItemQuantityStrategy'] ||
                    this.product.quantityStrategy;
            },
            showBackToBasketBtn() {
                return ['OrderItemDetail', 'BundleItemDetail'].includes(this.$route.name);
            },

            allBundleItemsSetZero() {
                if (!Array.isArray(this.bundleItems) || this.bundleItems.length === 0) {
                    return false;
                }
                return this.bundleItems.reduce((allZero, item) => allZero && item.quantity === 0, true);
            },
            showVariantAttrs() {
                return 'variantAttrs' in this.product &&
                    this.product.variantAttrs instanceof Object &&
                    Object.keys(this.product.variantAttrs).length > 0;
            },
            showProductFeatures() {
                return !this.product.bundleParent &&
                    'productFeatures' in this.product &&
                    this.product.productFeatures instanceof Array &&
                    this.product.productFeatures.length > 0;
            },
            availableBudgets() {
                return Array.isArray(this.product.budgets) ? this.product.budgets : [];
            },
            budgetRelevantCosts() {
                return parseFloat(this.priceNumber) + parseFloat(this.additionalPrice);
            },
            supportNoBudgetUse() {
                return this.availableBudgets.reduce((prevVal, budget) => prevVal || !budget.restrictedProducts, false);
            },
            budgetValid() {
                return !this.availableBudgets.length ||
                    (this.selectedBudgetId !== BUDGET_NONE_VALUE &&
                        (this.supportNoBudgetUse || this.selectedBudgetId !== null));
            }
        },
        watch: {
            product: {
                deep: true,
                immediate: true,
                handler() {
                    if (this.availableBudgets.length === 1 && !this.supportNoBudgetUse) {
                        this.selectedBudgetId = this.availableBudgets[0].id;
                    } else {
                        this.selectedBudgetId = BUDGET_NONE_VALUE;
                    }
                }
            }
        },
        methods: {
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            loadNewDetails(attr) {
                this.productFeaturesValid = true;
                this.$store.dispatch('productDetail/findNewVariant', {
                    productId: this.product.id,
                    attr: attr
                }).catch((error) => {
                    console.error(error);
                });
            },
            collectProductFeatures() {
                let productFeatures = [];
                for (let idx in this.product.productFeatures) {
                    productFeatures.push({
                        key: this.product.productFeatures[idx].key,
                        value: this.product.productFeatures[idx].value,
                        type: this.product.productFeatures[idx].type
                    });
                }

                return productFeatures;
            },
            quickCheckout() {
                if (!this.orderBtnActive) {
                    return;
                }

                this.basketActionRunning = true;

                this.$store.dispatch('quickCheckout/setProduct', {
                    productId: this.product.id,
                    quantity: this.quantity,
                    productFeatureValues: this.collectProductFeatures(),
                    desiredBudgetId: this.selectedBudgetId !== BUDGET_NONE_VALUE ? this.selectedBudgetId : null
                }).then((data) => {
                    this.quickCheckoutData = data;
                    this.showQuickCheckout = true;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.basketActionRunning = false;
                });
            },
            cancelQuickCheckout() {
                this.showQuickCheckout = false;
                this.$store.dispatch('quickCheckout/clear');
            },
            orderQuickCheckout() {
                this.$store.dispatch('quickCheckout/order')
                    .then((orderId) => {
                        const route = JSON.parse(JSON.stringify(
                            this.$store.getters['shop/getRouteByType']('orderSuccess')
                        ));
                        route.params.orderId = orderId;

                        this.$router.push(route);
                    })
                    .catch((error) => {
                        console.error('QuickCheckout failure', error);
                        this.showQuickCheckout = false;
                        this.quickCheckoutFailed = true;
                    });
            },
            setAddressQuickCheckout(data) {
                this.$store.dispatch('quickCheckout/setAddress', {
                    addressType: data.type,
                    addressId: data.id
                }).then((data) => {
                    this.quickCheckoutData = data;
                });
            },
            addToBasket() {
                if (!this.orderBtnActive) {
                    return;
                }

                this.basketActionRunning = true;

                if (this.bundle) {
                    this.$store.dispatch('bundleConfigurator/moveBundleToBasket', {
                        bundleId: this.bundle.bundleId
                    }).then(() => {
                        // reload fresh product
                        this.$store.dispatch('basket/getBasket').then((result) => {
                            this.$store.dispatch('basket/showBasketFlyIn', result.bundleTrees[0]);
                            this.$emit('refresh-configurator');
                        });
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.basketActionRunning = false;
                    });
                } else {
                    this.$store.dispatch('basket/addToBasket', {
                        productId: this.product.id,
                        quantity: this.quantity,
                        productFeatureValues: this.collectProductFeatures(),
                        getFullBasket: true,
                        desiredBudgetId: this.selectedBudgetId !== BUDGET_NONE_VALUE ? this.selectedBudgetId : null
                    }).then((result) => {
                        // reload fresh product
                        this.$store.dispatch('productDetail/find', {
                            productId: this.product.id
                        });
                        this.$store.dispatch('basket/showBasketFlyIn', result.bundleTrees[0]);
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.basketActionRunning = false;
                    });
                }
            },
            addToBasketWithCr() {
                if (!this.orderBtnActive) {
                    return;
                }

                this.basketActionRunning = true;

                this.$store.dispatch('basket/addToBasket', {
                    productId: this.product.id,
                    quantity: this.quantity,
                    productFeatureValues: this.collectProductFeatures(),
                    getFullBasket: true
                }).then((result) => {
                    const orderItemId = result.bundleTrees[0].orderItemId;
                    const orderItem = this.$store.getters['basket/getOrderItem'](orderItemId);
                    if (orderItem.campaignRun.isReady) {
                        // reload fresh product
                        this.$store.dispatch('productDetail/find', {
                            productId: this.product.id
                        });
                        this.$store.dispatch('basket/showBasketFlyIn', result.bundleTrees[0]);
                    } else {
                        this.campaignRunId = orderItem.campaignRun.id;
                    }
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.basketActionRunning = false;
                });
            },
            onCloseCrEditor() {
                this.campaignRunId = null;
            },
            updateBasketItem() {
                if (!this.updateBtnActive) {
                    return;
                }

                this.basketActionRunning = true;

                let productFeatures = [];
                for (let idx in this.product.productFeatures) {
                    productFeatures.push({
                        key: this.product.productFeatures[idx].key,
                        value: this.product.productFeatures[idx].value,
                        type: this.product.productFeatures[idx].type
                    });
                }

                this.$store.dispatch('basket/updateBasketItem', {
                    orderItemId: this.$store.getters['productDetail/orderItemId'],
                    quantity: this.quantity,
                    productFeatureValues: productFeatures
                }).then((result) => {
                    console.log(result);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.basketActionRunning = false;
                });
            },
            changeQuantity(newQuantity) {
                this.$store.dispatch('productDetail/setQuantity', {
                    quantity: newQuantity
                }).then(() => {
                    this.$nextTick(
                        () => this.informAboutQuantityAdaption(+newQuantity, this.quantity)
                    );
                });
            },
            informAboutQuantityAdaption(wantedQuantity, isQuantity) {
                if (wantedQuantity !== isQuantity) {
                    this.quantityChangedMsg = this.$t(
                        'message.informAboutQuantityAdaption',
                        {
                            wantedQuantity: this.$n(wantedQuantity),
                            isQuantity: this.$n(isQuantity),
                            unit: this.product.quantityUnit.shortName
                        }
                    );
                    this.quantityChangedNote = true;
                }
            },
            updateFeature(data) {
                this.$store.dispatch('productDetail/updateProductFeature', {
                    feature: data.feature,
                    value: data.value
                });
            },
            gotoBasket() {
                this.$router.push(this.$store.getters['shop/getRouteByType']('basket'));
            },

            setNewBudget(budgetId) {
                this.selectedBudgetId = budgetId;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../style/variables_bootstrap";
    @import "../../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    .price-wrapper {
        margin-top: 32px;

        .price-label {
            @extend %font-body2;
        }

        .price {
            @extend %font-h3;
            font-size: 22px;
        }
    }

    .last-order {
        @extend %font-caption;
        margin-top: 16px;
    }

    .fail {
        fill: var(--bs-danger);
    }
</style>
