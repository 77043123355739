<template>
    <div class="productGroup roundedBorder p-3">
        <NdxCheckbox
            v-if="bulkModeActive"
            :label="$t('btn.selectAll')"
            class="multiSelector"
            @update:modelValue="(val) => this.selectAll(val)"
        />
        <template v-for="bundle in bundles" :key="bundle.bundleId">
            <div class="bundleItemContainer d-flex">
                <NdxCheckbox
                    v-if="bulkModeActive"
                    :model-value="isSelected(bundle.bundleId)"
                    @update:model-value="(val) => selectBundle(val, bundle.bundleId)"
                />
                <div class="flex-fill">
                    <BundleTree
                        v-if="bundle.bundleProduct"
                        type="basket"
                        :bundle="bundle"
                        :order-items="getOrderItems"
                        @refresh-basket="() => $emit('refresh-basket')"
                    />
                    <BasketGroupItem
                        v-else-if="bundle.orderItem"
                        :item="getOrderItem(bundle.orderItem.orderItemId)"
                        @refresh-basket="() => $emit('refresh-basket')"
                    />
                </div>
                <div
                    v-if="bulkModeActive"
                    class="clickCatcher"
                    :class="{selected: isSelected(bundle.bundleId)}"
                    @click="selectBundle(!isSelected(bundle.bundleId), bundle.bundleId)"
                ></div>
            </div>
        </template>
    </div>
</template>

<script>
    import BasketGroupItem from "./BasketGroupItem";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox.vue";
    import BundleTree from "../../checkout/steps/parts/BundleTree.vue";
    import {mapGetters} from "vuex";

    export default {
        name: "BasketProductGroup",
        components: {BundleTree, NdxCheckbox, BasketGroupItem},
        emits: ['refresh-basket', 'select-bundle'],
        props: {
            bundles: {
                type: Array,
                required: true
            },
            bulkModeActive: {
                type: Boolean
            },
            selectedBundles: {
                type: Array
            }
        },
        computed: {
            ...mapGetters('basket', {
                getOrderItem: 'getOrderItem',
                getOrderItems: 'getOrderItems'
            })
        },
        methods: {
            isSelected(bundleId) {
                return this.selectedBundles.indexOf(bundleId) !== -1;
            },
            selectBundle(val, bundleId) {
                this.$emit('select-bundle', val, bundleId);
            },
            selectAll(val) {
                this.bundles.forEach((bundle) => {
                   this.selectBundle(val, bundle.bundleId);
                });
            }
        }
    };
</script>

<style scoped lang="scss">

.productGroup {
    position: relative;
    .bundleTree {
        padding: 0 !important;
    }
    :deep(.bundleTree .children > .product-groupItem) {
        padding: 16px 0 16px 16px;
    }
    :deep(.product-groupItem + .product-groupItem),
    :deep(.bundleTree + .product-groupItem),
    :deep(.product-groupItem + .bundleTree),
    :deep(.bundleTree + .bundleTree) {
        border-top: 1px solid var(--bs-gray-middle);
    }
}
</style>
