<template>
    <div class="d-inline-flex align-items-center">
        <NdxSearchInput v-if="showSearch" v-model="searchStr" @on-key-enter="startSearch" :removable="true"/>
        <NdxMenuEntry
            v-for="item in navItemsFiltered"
            :key="item.id"
            :class="{
                'router-link-active': isActive(item),
                navIcon: true
            }"
            :wgc="item"
            icon-size="m"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import NdxMenuEntry from "../library/NdxMenuEntry";

    export default {
        name: 'MainNavIcon',
        components: {NdxMenuEntry, NdxSearchInput},
        data() {
            return {
                searchStr: ''
            };
        },
        computed: {
            ...mapState({
                navItems: state => state.shop.headerMenu,
                systemPages: state => state.shop.systemPages,
                searchStrStore: state => state.search.searchStr,
            }),
            navItemsFiltered() {
                return this.navItems.filter(item => item.type !== 'search');
            },
            showSearch() {
                for (let item of this.systemPages) {
                    if (item.type === 'search') {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted() {
            this.$router.afterEach((to) => {
                if (to.name !== 'SearchResults') {
                    this.searchStr = '';
                } else {
                    this.searchStr = this.searchStrStore;
                }
            });
        },
        methods: {
            startSearch() {
                // changes should be also applied in MainNavBurger.vue
                if (this.searchStr?.trim().length > 0 || this.$store.getters['search/tagIds'].length > 0) {
                    this.$store.dispatch('search/setSearchStr', this.searchStr);
                    if (this.$route.name !== 'SearchResults') {
                        this.$store.dispatch('search/clearTags');
                        this.$router.push({name: 'SearchResults'});
                    }
                }
            },
            isActive(item) {
                return this.$route && 'params' in this.$route && 'id' in this.$route.params &&
                    parseInt(this.$route.params.id, 10) === item.id;
            }
        }
    };
</script>

<style scoped>
    .ndx-search {
        margin-right: 24px;
    }

    .navIcon {
        color: black;
    }

    .navIcon + .navIcon {
        margin-left: 24px;
    }
</style>
